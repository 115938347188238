import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { SignIn, useAuth } from '@clerk/clerk-react';
import Navbar from '../../components/navbar/Navbar.jsx';

function LogIn() {
  const { isSignedIn } = useAuth();

  // Redirect if already signed in
  if (isSignedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  // Add event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Let Clerk handle the submission
  };

  return (
    <div className="min-h-screen bg-white flex flex-col antialiased">
      <Navbar />
      <div className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900">
              Welcome Back
            </h1>
            <p className="mt-2 text-sm text-gray-600">
              Sign in to access your dashboard
            </p>
          </div>

          <div onSubmit={handleSubmit}>
            <SignIn
              appearance={{
                layout: {
                  socialButtonsPlacement: "bottom",
                  socialButtonsVariant: "blockButton",
                  privacyPageUrl: "https://clerk.dev/privacy",
                  termsPageUrl: "https://clerk.dev/terms",
                  shimmer: false
                },
                elements: {
                  rootBox: "w-full",
                  card: "w-full shadow-none border border-gray-200 rounded-lg p-6 space-y-6",
                  main: "space-y-6",
                  form: "space-y-4",
                  formButtonPrimary: "w-full h-12 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors font-medium",
                  formFieldLabel: "text-sm text-gray-700 mb-2",
                  formFieldInput: "w-full h-12 px-4 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 outline-none appearance-none",
                  socialButtons: "grid grid-cols-1 sm:grid-cols-2 gap-3 w-full",
                  socialButtonsBlockButton: "flex justify-center items-center w-full h-12 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors space-x-2",
                  socialButtonsProviderIcon: "w-5 h-5",
                  dividerRow: "my-6 relative",
                  dividerLine: "absolute top-1/2 w-full border-t border-gray-200",
                  dividerText: "relative z-10 bg-white px-4 text-sm text-gray-500 mx-auto w-fit"
                }
              }}
              routing="hash"
              afterSignInUrl="/dashboard"
              signUpUrl="/signup"
              redirectUrl={window.location.origin + "/dashboard"}
              signUpMode="redirect"
            />
          </div>

          <div className="text-center text-sm text-gray-600 px-4">
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-600 hover:text-blue-700 font-medium">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;