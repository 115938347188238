import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { 
  MessageCircle,
  Brain,
  Layers, 
  Database, 
  Mail,
  X,
  AlertTriangle,
  CheckCircle2
} from 'lucide-react';
import Navbar from '../../components/navbar/Navbar.jsx';

const ProductDashboard = () => {
  const { user } = useUser();
  const [showEchidnaChatPopup, setShowEchidnaChatPopup] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('echidnaChatPopupSeen');
    if (!hasSeenPopup) {
      const timer = setTimeout(() => {
        setShowEchidnaChatPopup(true);
        localStorage.setItem('echidnaChatPopupSeen', 'true');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const products = [
    {
      id: 'echidna-chat',
      name: 'Echidna Chat',
      icon: MessageCircle,
      description: 'Enterprise Communication',
      status: 'operational',
      metrics: {
        uptime: '99.99%',
        response: '45ms'
      },
      link: '/echidna/chat'
    },
    {
      id: 'echidna-llm',
      name: 'Echidna LLM',
      icon: Brain,
      description: 'Language Processing',
      status: 'operational',
      metrics: {
        uptime: '99.95%',
        response: '150ms'
      },
      link: '/products/echidna-llm'
    },
    {
      id: 'neptaverse',
      name: 'Neptaverse XR',
      icon: Layers,
      description: 'Reality Engine',
      status: 'down',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/neptaverse'
    },
    {
      id: 'cyberlake',
      name: 'Cyberlake',
      icon: Database,
      description: 'Data Processing',
      status: 'down',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/cyberlake'
    },
    {
      id: 'echidna-mail',
      name: 'Echidna Mail',
      icon: Mail,
      description: 'Secure Email',
      status: 'down',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/echidnamail/dashboard'
    }
  ];

  return (
    <div className="min-h-screen bg-[#0D1117]">
      <Navbar />
      
      <div className="px-4 py-8 max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-xl text-gray-200">System Status</h1>
          <div className="flex items-center gap-2 px-3 py-1.5 bg-red-500/10 rounded">
            <AlertTriangle className="w-4 h-4 text-red-400" />
            <span className="text-sm text-red-400">Major Outage</span>
          </div>
        </div>

        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {products.map((product) => (
            <a 
              key={product.id}
              href={product.link}
              className="block bg-[#161B22] border border-gray-800 rounded-lg hover:border-gray-700 transition-colors"
            >
              <div className="p-4">
                <div className="flex items-center gap-3 mb-4">
                  <product.icon className="w-5 h-5 text-gray-400" />
                  <div>
                    <h3 className="text-gray-200 font-medium">{product.name}</h3>
                    <p className="text-sm text-gray-500">{product.description}</p>
                  </div>
                </div>
                
                <div className="flex items-center gap-2">
                  {product.status === 'operational' ? (
                    <CheckCircle2 className="w-4 h-4 text-emerald-400" />
                  ) : (
                    <AlertTriangle className="w-4 h-4 text-red-400" />
                  )}
                  <span className={`text-sm ${
                    product.status === 'operational' ? 'text-emerald-400' : 'text-red-400'
                  }`}>
                    {product.status === 'operational' ? 'Operational' : 'System Down'}
                  </span>
                </div>

                <div className="mt-3 pt-3 border-t border-gray-800/50 text-sm text-gray-400">
                  <div className="flex justify-between">
                    <span>Uptime</span>
                    <span>{product.metrics.uptime}</span>
                  </div>
                  <div className="flex justify-between mt-1">
                    <span>Response Time</span>
                    <span>{product.metrics.response}</span>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>

      {showEchidnaChatPopup && (
        <div className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4">
          <div className="bg-[#161B22] rounded-lg max-w-md w-full p-6 relative border border-gray-800">
            <button 
              onClick={() => setShowEchidnaChatPopup(false)}
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-300"
            >
              <X className="w-5 h-5" />
            </button>
            <div className="flex items-center gap-4 mb-4">
              <MessageCircle className="w-8 h-8 text-blue-400" />
              <h3 className="text-lg text-gray-200">Try Echidna Chat</h3>
            </div>
            <p className="text-gray-400 mb-6">
              Experience our flagship communication platform with enterprise-grade security and AI capabilities.
            </p>
            <button 
              onClick={() => {
                window.location.href = '/echidna/chat';
                setShowEchidnaChatPopup(false);
              }}
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Launch Platform
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDashboard;