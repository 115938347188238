import React from 'react';
import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, PieChart, Pie, Cell } from 'recharts';
import Navbar from '../../components/navbar/Navbar';

const TesseraDocs = () => {
 
    const EdgeCaseSection = () => {
        const edgeCaseData = [
          {
            month: 'Jan',
            embedded: 98.2,
            mobile: 95.4,
            iot: 92.1,
            serverless: 97.8
          },
          {
            month: 'Feb',
            embedded: 97.8,
            mobile: 96.2,
            iot: 93.4,
            serverless: 98.1
          }
        ];
      
        const resourceMetrics = [
          {
            scenario: 'Low-Memory',
            tessera: 82,
            baseline: 45,
            competitor: 63
          },
          {
            scenario: 'CPU-Constrained',
            tessera: 88,
            baseline: 51,
            competitor: 69
          },
          {
            scenario: 'Network-Limited',
            tessera: 91,
            baseline: 58,
            competitor: 72
          },
          {
            scenario: 'Power-Restricted',
            tessera: 85,
            baseline: 49,
            competitor: 67
          }
        ];
      
        const thermalData = [
          { time: '0', temp: 45, load: 30 },
          { time: '10', temp: 52, load: 45 },
          { time: '20', temp: 58, load: 60 },
          { time: '30', temp: 62, load: 75 },
          { time: '40', temp: 65, load: 85 },
          { time: '50', temp: 67, load: 90 },
          { time: '60', temp: 68, load: 92 }
        ];
      
        const failureModes = [
          { type: 'Memory Exhaustion', count: 245, resolved: 238 },
          { type: 'Thread Deadlock', count: 178, resolved: 172 },
          { type: 'Cache Thrashing', count: 156, resolved: 149 },
          { type: 'Stack Overflow', count: 134, resolved: 130 },
          { type: 'Buffer Overrun', count: 112, resolved: 109 }
        ];
      
        return (
          <section className="mb-20">
            <h2 className="text-4xl font-serif mb-8">Edge Case Deployments & Reliability Analysis</h2>
            
            <div className="bg-gray-50 p-8 rounded-lg mb-12">
              <h3 className="text-2xl font-serif mb-6">Deployment Stability Across Environments</h3>
              <div className="mb-8">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={edgeCaseData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[90, 100]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="embedded" name="Embedded Systems" stroke="#2563eb" />
                    <Line type="monotone" dataKey="mobile" name="Mobile Devices" stroke="#7c3aed" />
                    <Line type="monotone" dataKey="iot" name="IoT Devices" stroke="#db2777" />
                    <Line type="monotone" dataKey="serverless" name="Serverless" stroke="#059669" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <p className="text-lg mb-6">
                Our extensive deployment analysis across various edge environments shows remarkable stability, 
                with uptime exceeding 98% even in resource-constrained scenarios. The model demonstrates 
                particular resilience in embedded systems and serverless deployments.
              </p>
            </div>
      
            <div className="grid grid-cols-2 gap-8 mb-12">
              <div className="bg-blue-50 p-6 rounded-lg">
                <h4 className="text-xl font-serif mb-4">Resource Utilization Under Constraints</h4>
                <div className="mb-4">
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={resourceMetrics}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="scenario" angle={-45} textAnchor="end" height={60} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="tessera" name="Tessera" fill="#2563eb" />
                      <Bar dataKey="competitor" name="Competitor" fill="#7c3aed" />
                      <Bar dataKey="baseline" name="Baseline" fill="#9ca3af" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <p className="text-sm text-gray-600">
                  Performance maintained above 80% even under severe resource constraints.
                </p>
              </div>
      
              <div className="bg-blue-50 p-6 rounded-lg">
                <h4 className="text-xl font-serif mb-4">Thermal Performance Analysis</h4>
                <div className="mb-4">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={thermalData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis yAxisId="left" />
                      <YAxis yAxisId="right" orientation="right" />
                      <Tooltip />
                      <Legend />
                      <Line yAxisId="left" type="monotone" dataKey="temp" name="Temperature (°C)" stroke="#dc2626" />
                      <Line yAxisId="right" type="monotone" dataKey="load" name="Load (%)" stroke="#2563eb" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <p className="text-sm text-gray-600">
                  Thermal stability maintained even under sustained high load conditions.
                </p>
              </div>
            </div>
      
            <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h3 className="text-2xl font-serif mb-6">Failure Mode Analysis</h3>
              <div className="mb-8">
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={failureModes}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="type" angle={-45} textAnchor="end" height={100} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" name="Total Incidents" fill="#ef4444" />
                    <Bar dataKey="resolved" name="Resolved" fill="#22c55e" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <p className="text-lg mb-6">
                Through extensive testing across 10,000+ deployment scenarios, we've identified and addressed 
                key failure modes. Our resolution rate exceeds 96% across all categories, with automated 
                recovery mechanisms handling 82% of cases without human intervention.
              </p>
            </div>
      
            <div className="grid grid-cols-3 gap-6 mb-12">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-lg font-serif mb-4">Memory Management</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Dynamic memory allocation with 99.9% efficiency</li>
                  <li className="mb-2">Automatic garbage collection optimization</li>
                  <li className="mb-2">Memory leak prevention system</li>
                  <li className="mb-2">Adaptive cache management</li>
                </ul>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-lg font-serif mb-4">Thread Management</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Advanced deadlock detection</li>
                  <li className="mb-2">Automated thread pooling</li>
                  <li className="mb-2">Priority-based scheduling</li>
                  <li className="mb-2">Load balancing mechanisms</li>
                </ul>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h4 className="text-lg font-serif mb-4">Error Recovery</h4>
                <ul className="list-disc pl-4">
                  <li className="mb-2">Self-healing protocols</li>
                  <li className="mb-2">Redundancy management</li>
                  <li className="mb-2">State restoration system</li>
                  <li className="mb-2">Graceful degradation paths</li>
                </ul>
              </div>
            </div>
      
            <div className="bg-blue-50 p-8 rounded-lg">
              <h3 className="text-2xl font-serif mb-6">Deployment Recommendations</h3>
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <h4 className="text-xl font-serif mb-4">Minimum Requirements</h4>
                  <ul className="list-disc pl-6 mb-4">
                    <li className="mb-2">RAM: 2GB dedicated</li>
                    <li className="mb-2">CPU: 2 cores @ 1.5GHz</li>
                    <li className="mb-2">Storage: 2GB available</li>
                   
                  </ul>
                </div>
                <div>
                  <h4 className="text-xl font-serif mb-4">Optimal Configuration</h4>
                  <ul className="list-disc pl-6 mb-4">
                    <li className="mb-2">RAM: 3GB dedicated</li>
                    <li className="mb-2">CPU: 4 cores @ 2.5GHz</li>
                    <li className="mb-2">Storage: 4GB available</li>
             
                  </ul>
                </div>
              </div>
            </div>
          </section>
        );
      };
  const efficiencyData = [
    { month: 'Jan', latency: 120, throughput: 850, energy: 75 },
    { month: 'Feb', latency: 115, throughput: 900, energy: 72 },
    { month: 'Mar', latency: 100, throughput: 950, energy: 68 },
    { month: 'Apr', latency: 90, throughput: 1000, energy: 65 },
    { month: 'May', latency: 85, throughput: 1100, energy: 60 },
    { month: 'Jun', latency: 80, throughput: 1200, energy: 55 }
  ];

  const resourceUsage = [
    { name: 'CPU', value: 30 },
    { name: 'Memory', value: 45 },
    { name: 'Storage', value: 15 },
    { name: 'Network', value: 10 }
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const accuracyTrends = [
    { day: '1', baseline: 75, optimized: 79, compressed: 82 },
    { day: '2', baseline: 76, optimized: 80, compressed: 83 },
    { day: '3', baseline: 74, optimized: 79, compressed: 81 },
    { day: '4', baseline: 77, optimized: 81, compressed: 84 },
    { day: '5', baseline: 75, optimized: 80, compressed: 83 },
    { day: '6', baseline: 78, optimized: 82, compressed: 85 },
    { day: '7', baseline: 76, optimized: 81, compressed: 84 }
  ];

  const benchmarkData = [
    { benchmark: 'MATH', tessera: 52.1, previous: 45.3, baseline: 42.8 },
    { benchmark: 'GSM8K', tessera: 86.4, previous: 81.2, baseline: 78.5 },
    { benchmark: 'MMLU', tessera: 81.3, previous: 76.8, baseline: 72.4 },
    { benchmark: 'HumanEval', tessera: 73.2, previous: 67.5, baseline: 63.8 }
  ];

  const performanceData = [
    { capability: 'Reasoning & Logic', base: 82.4, previous: 78.2 },
    { capability: 'Mathematical Operations', base: 79.7, previous: 75.5 },
    { capability: 'Code Generation', base: 80.2, previous: 76.8 },
    { capability: 'Knowledge Retrieval', base: 84.1, previous: 79.3 },
    { capability: 'Text Generation', base: 83.5, previous: 78.7 },
    { capability: 'Context Understanding', base: 81.8, previous: 77.4 },
    { capability: 'Multilingual Support', base: 78.9, previous: 74.2 },
    { capability: 'Task Adaptation', base: 80.8, previous: 76.1 }
  ];

  const latencyComparison = [
    { size: '100MB', tessera: 45, previous: 85, baseline: 120 },
    { size: '200MB', tessera: 55, previous: 95, baseline: 140 },
    { size: '400MB', tessera: 65, previous: 110, baseline: 160 },
    { size: '800MB', tessera: 80, previous: 130, baseline: 190 }
  ];

  const memoryUsage = [
    { task: 'Inference', value: 512 },
    { task: 'Training', value: 2048 },
    { task: 'Fine-tuning', value: 1024 },
    { task: 'Quantization', value: 256 }
  ];

  return (
    <div>
      <Navbar />
      <article className="max-w-4xl mx-auto px-6 py-10 bg-white">
        {/* Enhanced Header */}
        <header className="mb-12 text-center">
          <h1 className="text-6xl font-serif mb-6">Echidna v4o Tessera</h1>
          <h2 className="text-3xl text-gray-600 mb-4">Redefining the Frontier of Efficient AI</h2>
          <div className="text-gray-600 text-sm">
            <time>February 1, 2025</time> · Research & Development
          </div>
        </header>

        {/* Lead Introduction */}
        <section className="mb-12">
        <p className="text-xl leading-relaxed mb-6">
            We're releasing Echidna v4o Tessera, our most efficient AI model to date, achieving 81.3% 
            on MMLU while maintaining a compact 800MB size. This breakthrough model advances the 
            boundaries of what small models can achieve, with particular strength in reasoning, 
            mathematical operations, and code generation—all while maintaining minimal computational 
            requirements.
          </p>
          <p className="text-xl leading-relaxed mb-6">
            Tessera is our first small model to support advanced features including dynamic attention 
            mechanisms, structured pruning, and adaptive quantization, making it production-ready from day one. 
            The model demonstrates unprecedented efficiency while matching or exceeding the performance of 
            models 10x its size across key benchmarks.
          </p>
        </section>

        {/* Key Benchmarks Section */}
        <section className="mb-16">
          <h2 className="text-4xl font-serif mb-8">Benchmark Performance</h2>
          <p className="text-lg mb-6">
            Tessera achieves strong results across standard benchmarks, with particularly impressive 
            performance on GSM8K (86.4%) and MMLU (81.3%), approaching the capabilities of much 
            larger models.
          </p>
          
          <div className="mb-12">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={benchmarkData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="benchmark" />
                <YAxis domain={[60, 90]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="tessera" name="Tessera v4o" fill="#2563eb" />
                <Bar dataKey="previous" name="Previous Version" fill="#60a5fa" />
                <Bar dataKey="baseline" name="Industry Baseline" fill="#93c5fd" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="grid grid-cols-2 gap-8 mb-12">
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-2xl font-serif mb-4">MATH Benchmark</h3>
              <p className="mb-4">
                Tessera achieves 52.1% accuracy on the MATH benchmark, representing a 6.8 percentage 
                point improvement over previous models while maintaining its efficient size.
              </p>
            </div>
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-2xl font-serif mb-4">GSM8K Performance</h3>
              <p className="mb-4">
                On GSM8K, Tessera demonstrates exceptional reasoning capabilities with 86.4% accuracy, 
                showing that small models can excel at complex mathematical reasoning tasks.
              </p>
            </div>
          </div>
        </section>

        {/* Efficiency Metrics */}
        <section className="mb-16">
          <h2 className="text-4xl font-serif mb-8">Model Efficiency</h2>
          
          <div className="mb-12">
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={latencyComparison}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="size" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="tessera" name="Tessera v4o" stroke="#2563eb" />
                <Line type="monotone" dataKey="previous" name="Previous Version" stroke="#60a5fa" />
                <Line type="monotone" dataKey="baseline" name="Industry Baseline" stroke="#93c5fd" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="grid grid-cols-2 gap-8 mb-12">
            <div>
              <h3 className="text-2xl font-serif mb-4">Latency Improvements</h3>
              <p className="mb-4">
                Tessera delivers responses 47% faster than previous models, with an average inference 
                time of just 80ms at full model size. This represents a significant advancement in 
                model efficiency while maintaining high accuracy.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-serif mb-4">Memory Optimization</h3>
              <p className="mb-4">
                Through advanced quantization and pruning techniques, Tessera achieves a 60% reduction 
                in memory usage compared to baseline models, making it ideal for deployment in 
                resource-constrained environments.
              </p>
            </div>
          </div>
        </section>

        {/* Technical Architecture */}
        <section className="mb-16">
          <h2 className="text-4xl font-serif mb-8">Technical Architecture</h2>
          
          <div className="mb-12 bg-gray-50 p-8 rounded-lg">
            <h3 className="text-2xl font-serif mb-6">Key Innovations</h3>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h4 className="text-xl font-serif mb-3">Adaptive Attention</h4>
                <p className="mb-4">
                  Dynamic attention mechanisms automatically adjust computational complexity based on 
                  input requirements, reducing overhead by up to 40% without sacrificing accuracy.
                </p>
              </div>
              <div>
                <h4 className="text-xl font-serif mb-3">Hybrid Quantization</h4>
                <p className="mb-4">
                  Multi-level quantization strategy combines 8-bit and 4-bit precision, optimized per 
                  layer based on sensitivity analysis.
                </p>
              </div>
              <div>
                <h4 className="text-xl font-serif mb-3">Structured Pruning</h4>
                <p className="mb-4">
                  Advanced pruning techniques remove redundant connections while preserving critical 
                  pathways, achieving 3x compression with minimal accuracy loss.
                </p>
              </div>
              <div>
                <h4 className="text-xl font-serif mb-3">Knowledge Distillation</h4>
                <p className="mb-4">
                  Multi-stage distillation pipeline preserves complex reasoning capabilities while 
                  reducing model size by 85%.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Performance Analysis */}
        <section className="mb-16">
          <h2 className="text-4xl font-serif mb-8">Comprehensive Performance Analysis</h2>
          
          {/* Previous charts remain... */}
          
          <div className="bg-blue-50 p-6 rounded-lg mb-12">
            <h3 className="text-2xl font-serif mb-4">Human Evaluation Results</h3>
            <p className="mb-4">
              In blind tests, expert evaluators preferred Tessera's responses 56% of the time compared 
              to baseline models. Key findings include:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>39% reduction in major reasoning errors</li>
              <li>42% improvement in explanation clarity</li>
              <li>51% faster response generation</li>
              <li>44% reduction in hallucination rates</li>
            </ul>
          </div>
        </section>
        <section>
            <EdgeCaseSection />
        </section>

        {/* Future Directions */}
        <section className="mb-16">
  <h2 className="text-4xl font-serif mb-8">Future Research Directions</h2>
  <p className="text-lg mb-6">
    The release of Tessera marks a significant step in our mission to create more efficient AI
    systems. As we continue pushing the boundaries of AI research and development, we are exploring
    a multitude of directions aimed at enhancing performance, sustainability, and accessibility.
    One of the most promising areas is <strong>automated architecture search</strong>, where neural networks
    can autonomously refine and optimize their structures for maximum efficiency. This approach
    allows us to create models that not only maintain high accuracy but also require fewer
    computational resources, making AI more scalable and deployable across various domains.
  </p>
  <p className="text-lg mb-6">
    Another crucial area of research is <strong>hardware optimization</strong>. With AI models growing in
    complexity, it is imperative to design algorithms that take full advantage of modern hardware
    accelerators such as GPUs, TPUs, and specialized AI chips. We are developing compression and
    pruning techniques that align with the capabilities of these processors, reducing latency and
    power consumption. By integrating AI more seamlessly with hardware, we can ensure that models
    run efficiently on both cloud-based servers and edge devices, paving the way for real-time AI
    applications in mobile and embedded systems.
  </p>
  <p className="text-lg mb-6">
    Energy efficiency remains a key focus as AI adoption continues to rise. The carbon footprint of
    training large-scale AI models has raised concerns about sustainability. To address this, we
    are investigating <strong>green AI methodologies</strong> that aim to reduce the energy demand of AI
    operations. Techniques such as knowledge distillation, weight sharing, and low-bit precision
    computations help create models that achieve high accuracy with significantly lower energy
    costs. Additionally, exploring alternative computing paradigms, such as neuromorphic and
    quantum computing, could redefine the efficiency landscape of AI in the coming years.
  </p>
  <p className="text-lg mb-6">
    The expansion of <strong>federated learning</strong> represents a major step forward in preserving data
    privacy while still benefiting from collaborative AI training. This technique enables models to
    learn from decentralized data sources without transferring raw data to a central location,
    addressing concerns related to user privacy and data security. By leveraging differential
    privacy and secure multiparty computation, we can ensure that AI models improve while
    maintaining stringent privacy standards. Such advancements are particularly crucial for
    healthcare, finance, and other sensitive domains where data security is paramount.
  </p>
  <p className="text-lg mb-6">
    The integration of <strong>quantum AI</strong> is another avenue of research that could revolutionize
    computational speed and problem-solving capabilities. Quantum algorithms have the potential to
    drastically reduce the time required for complex optimizations, accelerating AI model training
    and inference beyond classical limits. As quantum hardware matures, we are exploring hybrid
    approaches that combine classical deep learning techniques with quantum enhancements to unlock
    unprecedented performance gains.
  </p>
  <p className="text-lg mb-6">
    One of the biggest challenges in AI adoption is the black-box nature of many deep learning
    models. To address this, we are investing in <strong>explainability and transparency</strong> research,
    aiming to develop interpretable AI systems. Techniques such as attention visualization,
    feature attribution, and symbolic reasoning are being incorporated to provide clearer
    explanations for AI-driven decisions. This research not only enhances trust in AI systems but
    also ensures compliance with ethical and regulatory frameworks.
  </p>
  <p className="text-lg mb-6">
    <strong>Self-supervised learning</strong> is another frontier with immense potential, as it allows AI
    models to learn representations from unlabeled data, significantly reducing the need for costly
    human annotations. By designing robust pretraining objectives, we can enable AI systems to
    generalize better across diverse tasks, making them more adaptable and scalable. Such research
    is crucial for extending AI capabilities to low-resource languages and specialized domains where
    labeled data is scarce.
  </p>
  <p className="text-lg mb-6">
    <strong>Adaptive learning systems</strong> are emerging as a new paradigm where AI models continuously
    update themselves based on real-time environmental feedback. This direction is especially
    relevant for robotics, autonomous systems, and personalized AI assistants, where static models
    often struggle to keep up with changing scenarios. By leveraging reinforcement learning and
    meta-learning techniques, we aim to build AI that is more flexible, resilient, and responsive
    to dynamic conditions.
  </p>
  <p className="text-lg mb-6">
    In addition to technical advancements, we are also focused on the societal implications of AI.
    Ensuring <strong>fairness and bias mitigation</strong> is an integral part of our research efforts.
    Addressing biases in training data, developing fairness-aware algorithms, and creating robust
    evaluation metrics are key steps toward building AI systems that are more equitable and
    inclusive. Moreover, fostering interdisciplinary collaborations with ethicists, policymakers,
    and industry leaders will help guide responsible AI deployment.
  </p>
  <p className="text-lg mb-6">
    Lastly, our research extends into <strong>AI-driven scientific discovery</strong>, where machine learning
    assists researchers in fields like materials science, drug discovery, and climate modeling. By
    automating hypothesis generation and data analysis, AI is accelerating breakthroughs that were
    previously out of reach. Through cross-disciplinary partnerships, we are leveraging AI to
    unlock new frontiers in science and engineering.
  </p>
  <p className="text-lg mb-6">
    The future of AI is incredibly promising, and our research directions continue to evolve as
    technology advances. By addressing challenges in efficiency, transparency, adaptability, and
    fairness, we aim to create AI systems that are not only powerful but also ethical and
    sustainable. Our ongoing efforts are driven by the belief that AI should be accessible,
    responsible, and beneficial for all, shaping a future where intelligent systems augment human
    capabilities and improve lives globally.
  </p>
</section>


        {/* Conclusion */}
        <section className="mb-12">
          <h2 className="text-4xl font-serif mb-8">Looking Ahead</h2>
          <p className="text-lg mb-6">
            Tessera demonstrates that the future of AI isn't just about building bigger models—it's 
            about building smarter, more efficient ones. By pushing the boundaries of model 
            compression and optimization, we're making advanced AI capabilities more accessible and 
            sustainable than ever before.
          </p>
        </section>
      </article>
    </div>
  );
};

export default TesseraDocs;