import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MoreHorizontal, Copy, Check, MessageSquare, Send, Brain, Code } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useUser } from '@clerk/clerk-react';

const CodeBlock = ({ content, language }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="my-2 rounded-md overflow-hidden bg-[#282c34] border border-zinc-800">
      <div className="flex justify-between items-center px-2 sm:px-3 py-1.5 bg-zinc-900/50">
        <span className="text-xs text-zinc-400 font-mono">{language || 'code'}</span>
        <button
          onClick={handleCopy}
          className="text-zinc-400 hover:text-white transition-colors p-1"
        >
          {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
        </button>
      </div>
      <div className="text-sm overflow-x-auto">
        <SyntaxHighlighter
          language={language || 'plaintext'}
          style={oneDark}
          customStyle={{
            margin: 0,
            padding: '0.75rem',
            background: 'transparent',
            fontSize: '0.875rem'
          }}
        >
          {content}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const ThinkingBlock = ({ content }) => {
  return (
    <div className="my-2 rounded-md overflow-hidden bg-zinc-800/50 border border-zinc-700">
      <div className="flex items-center space-x-2 px-3 py-2 bg-zinc-900/50">
        <Brain className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-zinc-400" />
        <span className="text-xs sm:text-sm text-zinc-400">Thinking process</span>
      </div>
      <div className="p-2 sm:p-3">
        <p className="text-xs sm:text-sm text-zinc-300 whitespace-pre-wrap">{content}</p>
      </div>
    </div>
  );
};

const SelectionPopover = ({ selectedText, onAsk }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (selectedText) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      
      setPosition({
        x: Math.min(rect.left + rect.width / 2, window.innerWidth - 100),
        y: Math.max(rect.top - 10, 50)
      });
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [selectedText]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed z-50 transform -translate-x-1/2 -translate-y-full touch-none"
      style={{ left: position.x, top: position.y }}
    >
      <button
        onClick={onAsk}
        className="flex items-center space-x-1 px-3 py-2 bg-zinc-800 text-white text-sm rounded-md hover:bg-zinc-700 transition-colors shadow-lg"
      >
        <MessageSquare className="h-4 w-4" />
        <span>Ask about selection</span>
      </button>
    </div>
  );
};

const ModelSelector = ({ currentModel, onModelChange }) => {
  return (
    <div className="flex space-x-1 p-1 bg-zinc-900 rounded-lg">
      <button
        onClick={() => onModelChange('think')}
        className={`flex items-center space-x-1 px-2 py-1 rounded-md text-xs transition-colors ${
          currentModel === 'think' 
            ? 'bg-zinc-700 text-white' 
            : 'text-zinc-400 hover:text-white'
        }`}
      >
        <Brain className="h-3 w-3" />
        <span>Think</span>
      </button>
      <button
        onClick={() => onModelChange('code')}
        className={`flex items-center space-x-1 px-2 py-1 rounded-md text-xs transition-colors ${
          currentModel === 'code' 
            ? 'bg-zinc-700 text-green-500' 
            : 'text-zinc-400 hover:text-white'
        }`}
      >
        <Code className="h-3 w-3" />
        <span>Code</span>
      </button>
    </div>
  );
};

const AIChatInterface = () => {
  const [currentModel, setCurrentModel] = useState('think');
  const [messages, setMessages] = useState([
    { 
      role: 'ai', 
      content: "Hello! I'm Echidna. Choose your model to get started.", 
      id: '1',
      model: 'think'
    }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [followUpPrompt, setFollowUpPrompt] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const { user } = useUser();
  
  const messagesEndRef = useRef(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    const handleSelection = () => {
      const selection = window.getSelection();
      const text = selection.toString().trim();
      
      if (text && text.length > 0) {
        setSelectedText(text);
      } else {
        setSelectedText('');
      }
    };

    document.addEventListener('mouseup', handleSelection);
    document.addEventListener('keyup', handleSelection);
    
    return () => {
      document.removeEventListener('mouseup', handleSelection);
      document.removeEventListener('keyup', handleSelection);
    };
  }, []);

  const handleSelectionAsk = () => {
    if (selectedText) {
      setInput(`Regarding this text: "${selectedText}"\nMy question: `);
      setSelectedText('');
      setTimeout(() => {
        const inputEl = document.querySelector('input[type="text"]');
        if (inputEl) {
          inputEl.focus();
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const formatMessage = (content) => {
    const parts = [];
    let currentBuffer = '';
    let inThinkBlock = false;
    let inCodeBlock = false;
    let currentLanguage = '';
    
    const pushCurrentBuffer = (type = 'text') => {
      if (currentBuffer) {
        if (type === 'code') {
          parts.push({
            type: 'code',
            language: currentLanguage || 'plaintext',
            content: currentBuffer.trim()
          });
        } else {
          parts.push({
            type,
            content: currentBuffer
          });
        }
        currentBuffer = '';
      }
    };
    
    const textContent = content || '';
    let i = 0;
    
    while (i < textContent.length) {
      if (textContent.slice(i, i + 7) === '<think>') {
        pushCurrentBuffer('text');
        inThinkBlock = true;
        i += 7;
        continue;
      }
      
      if (textContent.slice(i, i + 8) === '</think>' && inThinkBlock) {
        pushCurrentBuffer('think');
        inThinkBlock = false;
        i += 8;
        continue;
      }
      
      if (textContent.slice(i, i + 3) === '```' && !inCodeBlock) {
        pushCurrentBuffer('text');
        inCodeBlock = true;
        currentLanguage = '';
        i += 3;
        
        let tempIndex = i;
        while (tempIndex < textContent.length && textContent[tempIndex] !== '\n') {
          currentLanguage += textContent[tempIndex];
          tempIndex++;
        }
        i = tempIndex + 1;
        continue;
      }
      
      if (textContent.slice(i, i + 3) === '```' && inCodeBlock) {
        pushCurrentBuffer('code');
        inCodeBlock = false;
        currentLanguage = '';
        i += 3;
        continue;
      }
      
      currentBuffer += textContent[i];
      i++;
    }
    
    if (currentBuffer) {
      pushCurrentBuffer(inThinkBlock ? 'think' : 'text');
    }
    
    return parts;
  };

  const handleStop = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  };

  const handleFollowUp = async (messageId) => {
    if (!followUpPrompt.trim() || isLoading) return;

    const originalMessage = messages.find(m => m.id === messageId);
    if (!originalMessage) return;

    const prompt = `Regarding this: "${originalMessage.content}"\n\nFollow-up: ${followUpPrompt}`;
    
    setFollowUpPrompt('');
    setSelectedMessage(null);
    
    const userMessage = { 
      role: 'user', 
      content: followUpPrompt,
      id: Date.now().toString(),
      replyTo: messageId 
    };
    setMessages(prev => [...prev, userMessage]);
    
    await handleSend(prompt);
  };

  const handleModelChange = (model) => {
    setCurrentModel(model);
    setInput('');
  };

  const handleSend = async (customPrompt = null) => {
    const messageToSend = customPrompt || input;
    if (!messageToSend.trim() || isLoading) return;
    
    if (!customPrompt) {
      const userMessage = { 
        role: 'user', 
        content: messageToSend,
        id: Date.now().toString(),
        model: currentModel
      };
      setMessages(prev => [...prev, userMessage]);
      setInput('');
    }
  
    setIsLoading(true);
    setError(null);
  
    abortControllerRef.current = new AbortController();
  
    try {
      const endpoint = currentModel === 'think' 
        ? 'https://web-production-5ee8.up.railway.app/echidna/echatt' 
        : 'https://web-production-5ee8.up.railway.app/echidna/echat';

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: messageToSend,
          userId: user.id,
          model: currentModel
        }),
        signal: abortControllerRef.current.signal
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 403) {
          throw new Error(`Chat limit reached. Your limit: ${errorData.limit}, Used: ${errorData.used}`);
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      
      const newMessageId = Date.now().toString();
      setMessages(prev => [...prev, { 
        role: 'ai', 
        content: '', 
        id: newMessageId,
        model: currentModel 
      }]);
  
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        buffer += decoder.decode(value, { stream: true });
        
        const chunks = buffer.split('\n\n');
        
        buffer = chunks.reduce((acc, chunk) => {
          chunk = chunk.trim();
          if (!chunk) return acc;
          
          const dataMatch = chunk.match(/^data: data: (.+)$/);
          if (dataMatch) {
            try {
              const parsed = JSON.parse(dataMatch[1]);
              const content = parsed?.choices?.[0]?.delta?.content || '';
              
              if (content) {
                setMessages(prev => {
                  const newMessages = [...prev];
                  const lastMessage = newMessages[newMessages.length - 1];
                  lastMessage.content += content;
                  return newMessages;
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
            return acc;
          }
          
          if (chunk === 'data: [DONE]') {
            return acc;
          }
          
          return acc + chunk + '\n\n';
        }, '');
      }
  
    } catch (error) {
      if (error.name === 'AbortError') return;
      setError(error.message || 'Connection error. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="flex h-[100dvh] bg-black">
      <div className="flex-1 flex flex-col w-full max-w-4xl mx-auto">
        {/* Header */}
        <div className="sticky top-0 z-10 backdrop-blur-sm bg-black/50 border-b border-zinc-800">
          <div className="flex items-center justify-between p-2.5 sm:p-3">
            <div className="flex flex-col space-y-2">
              <h1 className="text-zinc-400 text-sm font-mono tracking-tight">
                echidna-v4o
                <span className={`text-[0.7rem] ml-1 ${
                  currentModel === 'code' ? 'text-green-500' : 'text-red-500'
                }`}>
                  {currentModel}
                </span>
              </h1>
              <ModelSelector 
                currentModel={currentModel}
                onModelChange={handleModelChange}
              />
            </div>
            {isLoading && (
              <button
                onClick={handleStop}
                className="text-xs text-zinc-500 hover:text-white transition-colors px-3 py-1.5 rounded-full border border-zinc-800"
              >
                Stop
              </button>
            )}
          </div>
        </div>
  
        {/* Error message */}
        {error && (
          <div className="px-3 py-2 text-xs text-red-400 border-b border-zinc-800 bg-red-500/10">
            {error}
          </div>
        )}
  
        {/* Messages area */}
        <div className="flex-1 overflow-y-auto overscroll-contain space-y-3 p-3">
          {messages.map((message) => (
            <div key={message.id} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`group relative max-w-[94%] sm:max-w-[85%] ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block px-3 py-2.5 rounded-lg ${
                  message.role === 'user' 
                    ? 'bg-white text-black' 
                    : `bg-zinc-900 text-white border ${
                        message.model === 'code' ? 'border-green-900/50' : 'border-zinc-800'
                      }`
                }`}>
                  {formatMessage(message.content).map((part, index) => {
                    switch (part.type) {
                      case 'code':
                        return (
                          <CodeBlock 
                            key={index} 
                            content={part.content} 
                            language={part.language} 
                          />
                        );
                      case 'think':
                        return <ThinkingBlock key={index} content={part.content} />;
                      default:
                        return (
                          <p key={index} className="text-sm leading-relaxed whitespace-pre-wrap break-words">
                            {part.content}
                          </p>
                        );
                    }
                  })}
                </div>
  
                {message.role === 'ai' && (
                  <button 
                    onClick={() => setSelectedMessage(selectedMessage === message.id ? null : message.id)}
                    className="absolute -right-7 top-2.5 opacity-0 group-hover:opacity-100 focus:opacity-100 transition-opacity"
                  >
                    <MoreHorizontal className="h-4 w-4 text-zinc-600 hover:text-white" />
                  </button>
                )}
  
                {selectedMessage === message.id && (
                  <div className="mt-2.5 flex flex-col space-y-2">
                    <input
                      type="text"
                      value={followUpPrompt}
                      onChange={(e) => setFollowUpPrompt(e.target.value)}
                      placeholder="Ask a follow-up question..."
                      className="w-full px-3.5 py-2 text-sm bg-zinc-900 text-white border border-zinc-800 rounded-lg focus:outline-none focus:border-zinc-700 focus:ring-1 focus:ring-zinc-700"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleFollowUp(message.id);
                        if (e.key === 'Escape') setSelectedMessage(null);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          
          {/* Loading indicator */}
          {isLoading && (
            <div className="flex justify-start">
              <div className="px-3 py-2.5 rounded-lg bg-zinc-900 text-white border border-zinc-800">
                <div className="w-5 h-5 flex items-center">
                  <div className="w-2 h-2 bg-zinc-400 rounded-full animate-pulse" />
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} className="h-1" />
        </div>
  
        {/* Input area - Improved mobile styling */}
        <div className="sticky bottom-0 backdrop-blur-sm bg-black/50 border-t border-zinc-800">
  <div className="p-3 sm:p-4">
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        handleSend();
      }}
      className="relative flex items-end gap-2"
    >
      <div className="flex-1">
        <input
          type="text"
          className={`w-full px-4 py-3 bg-zinc-900 text-sm text-white border rounded-xl placeholder-zinc-600 focus:outline-none focus:ring-1 ${
            currentModel === 'code'
              ? 'border-green-900/50 focus:border-green-700/70 focus:ring-green-700/50'
              : 'border-zinc-800 focus:border-zinc-700 focus:ring-zinc-700/50'
          } disabled:opacity-50`}
          placeholder={isLoading ? 'Please wait...' : `Message Echidna (${currentModel} mode)...`}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
      </div>
      <button
        type="submit"
        disabled={isLoading || !input.trim()}
        className={`shrink-0 flex items-center space-x-2 px-4 py-3 rounded-xl transition-colors disabled:opacity-50 ${
          currentModel === 'code'
            ? 'text-green-500 hover:bg-green-500/10'
            : 'text-white hover:bg-zinc-800'
        }`}
      >
        <span className="text-sm">Send</span>
        <Send className="h-4 w-4" />
      </button>
    </form>
  </div>
</div>
      </div>
      
      {/* Selection popover */}
      <SelectionPopover 
        selectedText={selectedText} 
        onAsk={handleSelectionAsk}
      />
    </div>
  );
};

export default AIChatInterface;