import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import { Toaster } from 'react-hot-toast';
import Navbar from './components/navbar/Navbar'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import SignUp from './pages/signup/SignUp'
import LogIn from './pages/login/LogIn'
import ESuccess from './pages/paymentsucess/esuccess'

import Home from './pages/home/Home'
import EnterpriseDashboard from './pages/Echidnamail/Edash.jsx'
import View from './pages/view/View'
import About from './pages/about/About'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Echdinachat from './pages/cyberlake/echidnachat'
import Echidnamail from './pages/cyberlake/echidnamail'
import PasswordReset from './pages/PasswordReset/PasswordReset'
import Waitlist from './pages/waitlist/Waitlist'
import Contact from './pages/contact/Contact'
import Dashboard from './pages/dashboard/Dashboard'
import Upload from './pages/addproduct/Upload'
import ViewProduct from './pages/viewproduct/ViewProduct'
import AIDealsRoom from './pages/Echidnamail/aidealroom.jsx'
import Market from './pages/marketplace/Market'
import Preview from './pages/previewobj/prev'
import Published from './pages/Published/Published'
import ViewObject from './pages/VR/vr'
import {NextUIProvider} from "@nextui-org/system";
import ARScene from './pages/AR/ar'
import Arsettings from './pages/arSettings/arsettings'
import Cyberdash from './pages/cyberlake/cyberlakedash'
import Cyberlakeupload from './pages/cyberlake/cyberupload'
import FuturisticCreditPage from './pages/cyberlake/addcredits'
import PricingCard from './pages/cyberlake/plans'
import PaymentPage from './pages/cyberlake/paymentpage'
import OrganizationsPage from './pages/Echidnamail/Eorg.jsx'
import CompetitorIntelligenceDashboard from './pages/Echidnamail/compana.jsx'
import TlandingPage from './pages/Androforce/tlanding'
import ThreeDViewer from './pages/cyberlake/cyberlake3d'
import PublicView from './pages/cyberlake/cyberpublicview'
import Cyberhome from './pages/cyberhome/cyberhome'
import Neptadash from './pages/neptaverse/neptadash'
import { Theme } from '@radix-ui/themes';
import Echidna from './pages/Androforce/echidna'
import BuyCredits from './pages/cyberlake/echidnacredits'
import PaymentPage5 from './pages/cyberlake/echpayments/5moplan'
import PaymentPage20 from './pages/cyberlake/echpayments/20moplan'
import PaymentPage150 from './pages/cyberlake/echpayments/150moplan'
import ProspectPage from './pages/cyberlake/cyberpro1'
import FrontendGenerator from './pages/neptaverse/codegen'
import TextGenPay from './pages/Androforce/textgenpay'
import AIResearchWriterApp from './pages/Androforce/resphrase'
import StudyApp from './pages/Androforce/Echstudy'
import UserInfo from './pages/Echidnamail/userinfo.jsx'
import EmailDashboard from './pages/Androforce/echmail'
import CampaignDashboard from './pages/Echidnamail/Ecamp'
import ProspectManagement from './pages/Echidnamail/Elead'
import Analytics from './pages/Echidnamail/Eana'
import Automations from './pages/Echidnamail/Eauto'
import AIModelDeployment from './pages/Androforce/aimodeldeploy'
import EmailTemplates from './pages/Echidnamail/Etemp'
import CampaignSpecific from './pages/Echidnamail/campaignspecific.jsx'
import ResistanceDashboard from './pages/home/aiwaht.jsx'
import EmailInbox from './pages/Echidnamail/Emaildata.jsx'
 
import CRM from './pages/Echidnamail/Ecrm.jsx'
import AutonomousWebAgentUI from './pages/Echidnamail/eemp.jsx'
import ProspectManagement1 from './pages/Echidnamail/Prospectvalidation.jsx'
import AIChatInterface from './pages/Androforce/AIchat.jsx'
import ProductDashboard from './pages/dashboard/metadash.jsx'
import RLandingPage from './pages/Androforce/ellm.jsx'
import EchidnaDash from './pages/dashboard/echidnadash.jsx'
import AICRM from './pages/crm/crm.jsx'
import Ecode from './pages/Echidnamail/ecode.jsx'
import TesseraDocs from './pages/blogs/Tessera.jsx';
import Carc from './pages/signup/cacc.jsx';


const ProtectedRoute = ({ children }) => {
  return (
    <>
      <SignedIn>
        {children}
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

const UnprotectedRoute = ({ children }) => {
  return children;
};

// Modified PublicRoute to show content with optional sign-in
const PublicRoute = ({ children }) => {
  return (
    <>
      <SignedIn>
        {children}
      </SignedIn>
      <SignedOut>
        {children}
      </SignedOut>
    </>
  );
};

function App() {
  return (
<ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}>
      <NextUIProvider>
        <Theme>
          <Toaster position="top-right" />
          <Router>
            <Routes>
              {/* Unprotected routes - these won't show sign in */}
              <Route path="/signup" element={<UnprotectedRoute><SignUp /></UnprotectedRoute>} />
              <Route path="/login" element={<UnprotectedRoute><LogIn /></UnprotectedRoute>} />
              <Route path="/forgotpassword" element={<UnprotectedRoute><ForgotPassword /></UnprotectedRoute>} />
              <Route path="/auth/reset-password/:id/:token" element={<UnprotectedRoute><PasswordReset /></UnprotectedRoute>} />

              {/* Public routes - these will be accessible to everyone */}
              <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
              <Route path="/view" element={<PublicRoute><View /></PublicRoute>} />
              <Route path="/contact" element={<PublicRoute><Waitlist /></PublicRoute>} />
              <Route path="/vr/:objectname" element={<PublicRoute><ViewObject /></PublicRoute>} />
              <Route path="/ar/:objectname" element={<PublicRoute><ARScene /></PublicRoute>} />
              <Route path="/cyberlake/:objectname" element={<PublicRoute><PublicView /></PublicRoute>} />
              <Route path="/cyberlake" element={<PublicRoute><Cyberhome /></PublicRoute>} />
              <Route path="/neptaverse" element={<PublicRoute><Neptadash /></PublicRoute>} />
              <Route path="/echidna" element={<PublicRoute><Echidna /></PublicRoute>} />
              <Route path="/Echidna-LLM" element={<PublicRoute><RLandingPage /></PublicRoute>} />
              <Route path="/blogs/tessera" element={<PublicRoute><TesseraDocs /></PublicRoute>} />
              {/* Protected routes */}
              <Route path="/creatingacc" element={<ProtectedRoute><Carc /></ProtectedRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute><ProductDashboard /></ProtectedRoute>} />
              <Route path="/echidnamail" element={<ProtectedRoute><EmailDashboard /></ProtectedRoute>} />
              <Route path="/echidnamail/campaigns" element={<ProtectedRoute><CampaignDashboard /></ProtectedRoute>} />
              <Route path="/echidnamail/prospects" element={<ProtectedRoute><ProspectManagement /></ProtectedRoute>} />
              <Route path="/echidnamail/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
              <Route path="/echidnamail/automations" element={<ProtectedRoute><Automations /></ProtectedRoute>} />
              <Route path="/echidnamail/templates" element={<ProtectedRoute><EmailTemplates /></ProtectedRoute>} />
              <Route path="/cyberlake/upload" element={<ProtectedRoute><Cyberlakeupload /></ProtectedRoute>} />
              <Route path="/cyberlake/credits" element={<ProtectedRoute><FuturisticCreditPage /></ProtectedRoute>} />
              <Route path="/cyberlake/pricing" element={<ProtectedRoute><PricingCard /></ProtectedRoute>} />
              <Route path="/cyberlake/chat" element={<ProtectedRoute><Echdinachat /></ProtectedRoute>} />
              <Route path="/cyberlake/pay/:plan" element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />
              <Route path="/cyberlake/privateview/:objectname" element={<ProtectedRoute><ThreeDViewer /></ProtectedRoute>} />
              <Route path="/echidnamail/buycredits" element={<ProtectedRoute><BuyCredits /></ProtectedRoute>} />
              <Route path="/cyberlake/echpayments/5moplan" element={<ProtectedRoute><PaymentPage5 /></ProtectedRoute>} />
              <Route path="/cyberlake/echpayments/20moplan" element={<ProtectedRoute><PaymentPage20 /></ProtectedRoute>} />
              <Route path="/cyberlake/echpayments/150moplan" element={<ProtectedRoute><PaymentPage150 /></ProtectedRoute>} />
              <Route path="/echidnamail/:token" element={<ProtectedRoute><ESuccess /></ProtectedRoute>} />
              <Route path="/echidna/:campaign" element={<ProtectedRoute><CampaignSpecific /></ProtectedRoute>} />
              <Route path="/echidnamail/userinfo" element={<ProtectedRoute><UserInfo /></ProtectedRoute>} />
              <Route path="/echidnamail/emaildata" element={<ProtectedRoute><EmailInbox /></ProtectedRoute>} />
              <Route path="/echidnamail/crm" element={<ProtectedRoute><AICRM /></ProtectedRoute>} />
              <Route path="/echidnamail/prospectvalidation" element={<ProtectedRoute><ProspectManagement1 /></ProtectedRoute>} />
              <Route path="/echidna/codegenv0" element={<ProtectedRoute><FrontendGenerator /></ProtectedRoute>} />
              <Route path="/echidna/chat" element={<ProtectedRoute><AIChatInterface /></ProtectedRoute>} />
              <Route path="/echidnamail/dashboard" element={<ProtectedRoute><EchidnaDash /></ProtectedRoute>} />
              <Route path="/Echidnacode" element={<ProtectedRoute><Ecode /></ProtectedRoute>} />
              <Route path="/textgenv0/pay" element={<ProtectedRoute><TextGenPay /></ProtectedRoute>} />
              <Route path="/textgen" element={<ProtectedRoute><TlandingPage /></ProtectedRoute>} />
              <Route path="/echedu" element={<ProtectedRoute><StudyApp /></ProtectedRoute>} />
              <Route path="/aimodeldeploy" element={<ProtectedRoute><AIModelDeployment /></ProtectedRoute>} />
              <Route path="/ai" element={<ProtectedRoute><AutonomousWebAgentUI /></ProtectedRoute>} />

              {/* Catch all route - redirect to login */}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </Theme>
      </NextUIProvider>
    </ClerkProvider>
  );
}

export default App;