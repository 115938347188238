import React from 'react';
import { 
  ShieldCheck, Zap, Lock, DollarSign, Cloud, Clock, MessageSquare, 
  FileText, Brain, ChevronRight, Star, Users, Sparkles, Code, 
  Minimize2, Database, Shield, Server, Check, Search, 
  PenTool, Terminal, Monitor, Cpu, Calculator, Info, ArrowRight, 
  CreditCard, BarChart3 
} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { useState, useEffect } from 'react';

const Navbar = () => (
  <nav className="fixed w-full z-50 bg-black/30 backdrop-blur-sm border-b border-white/5">
    <div className="max-w-5xl mx-auto px-4 h-16 flex items-center justify-between">
      <div className="text-xl font-light text-white">Echidna AI</div>
      <a href='https://forms.gle/WxZiMyfDYrMxQSQA8'>
        <button className="px-4 py-2 rounded text-white hover:bg-white/10 border border-white/10 transition-colors">
          Apply
        </button>
      </a>
    </div>
  </nav>
);

const Hero = () => (
  <div className="relative min-h-screen bg-black flex items-center">
    <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(255,255,255,0.03),transparent_70%)]" />
    <div className="relative z-10 max-w-5xl mx-auto px-4 py-16 sm:py-32">
      <div className="max-w-2xl">
        <div className="inline-flex items-center px-3 py-1 rounded-full border border-white/10 text-white/70 text-sm mb-8">
          <Sparkles className="h-3 w-3 mr-2" />
          <span className="hidden sm:inline">50% More Efficient</span>
          <span className="sm:hidden">50% Better</span>
        </div>
        
        <h1 className="text-4xl sm:text-5xl font-light mb-8 leading-tight">
          <span className="text-white">The Next Generation</span>
          <br />
          <span className="text-white/70">of Artificial Intelligence</span>
        </h1>
        
        <p className="text-white/50 text-lg mb-12 leading-relaxed max-w-xl">
          Experience unmatched performance with our suite of AI models. 
          Built for enterprises, optimized for developers.
        </p>
        
        <a
          href="https://forms.gle/WxZiMyfDYrMxQSQA8"
          target="_blank"
          rel="noopener noreferrer"
          className="group inline-flex items-center px-6 py-3 border border-white/10 rounded text-white hover:bg-white/5 transition-all"
        >
          Apply Now
          <ChevronRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
        </a>
      </div>
    </div>
  </div>
);


const TerminalWindow = ({ title, icon: Icon, children, bgColor = "bg-black" }) => (
  <div className="bg-black/30 rounded-lg overflow-hidden w-full max-w-2xl mx-auto mb-4">
    <div className="bg-black/40 px-4 py-2 flex items-center gap-2">
      <Icon className="w-4 h-4 text-white/70" />
      <span className="text-white/70 text-sm">{title}</span>
    </div>
    <div className={`p-4 font-mono text-sm overflow-auto max-h-[500px] ${bgColor}`}>
      {children}
    </div>
  </div>
);

const TypingAnimation = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  
  useEffect(() => {
    let index = 0;
    setDisplayText('');
    
    const timer = setInterval(() => {
      if (index < text.length) {
        setDisplayText(prev => prev + text[index]);
        index++;
      } else {
        clearInterval(timer);
        onComplete();
      }
    }, 30); // Faster typing speed

    return () => clearInterval(timer);
  }, [text, onComplete]);

  return <span>{displayText}</span>;
};

const TerminalAnimation = () => {
  const [currentTerminal, setCurrentTerminal] = useState(0);
  const [animationPhase, setAnimationPhase] = useState('typing');
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const terminals = [
    {
      icon: Terminal,
      title: "PowerShell Terminal",
      prompt: "PS C:\\Apps>",
      bgColor: "bg-[#012456]",
      textColor: "text-[#eeedf0]",
      interaction: {
        command: "$body = @{model='v4o-code'; message='Write a PowerShell function to monitor system resources'} | ConvertTo-Json\nInvoke-RestMethod -Uri 'https://api.example.com/v1/chat' -Method 'POST' -Body $body -ContentType 'application/json'",
        connecting: "Connecting to v4o-code...",
        response: "Model        : v4o-code\nLatency      : 0.5s\nResponse     : function Get-SystemMetrics {\n    $cpu = Get-Counter '\\Processor(_Total)\\% Processor Time'\n    $memory = Get-Counter '\\Memory\\Available MBytes'\n    $disk = Get-Counter '\\LogicalDisk(_Total)\\% Free Space'\n\n    return @{\n        CPUUsage = $cpu.CounterSamples.CookedValue\n        MemoryAvailable = $memory.CounterSamples.CookedValue\n        DiskSpace = $disk.CounterSamples.CookedValue\n    }\n}"
      }
    },
    {
      icon: Monitor,
      title: "Windows Command Prompt",
      prompt: "C:\\Users\\Developer>",
      bgColor: "bg-black",
      textColor: "text-gray-200",
      interaction: {
        command: "curl -X POST https://api.example.com/v1/chat -d \"model=v4o-code&message=Write a batch script to backup files\"",
        connecting: "Connecting to v4o-code...",
        response: "{\n  \"model\": \"v4o-code\",\n  \"response\": \"@echo off\\nset source=C:\\Projects\\\\\\nset backup=D:\\Backup\\\\\\nset timestamp=%date:~-4,4%%date:~-10,2%%date:~-7,2%\\n\\nxcopy \\\"%source%\\\" \\\"%backup%\\%timestamp%\\\" /E /I /H /Y\\n\\necho Backup completed to %backup%\\%timestamp%\",\n  \"latency\": \"0.6s\"\n}"
      }
    },
    {
      icon: Cpu,
      title: "Raspberry Pi Terminal",
      prompt: "pi@raspberrypi:~$",
      bgColor: "bg-[#2b0d0d]",
      textColor: "text-[#e6e1dc]",
      interaction: {
        command: "python3 -c '\nimport requests\n\nresponse = requests.post(\"https://api.example.com/v1/chat\",\n    json={\"model\": \"v4o-code\", \"message\": \"Write a Python script to read sensor data on Raspberry Pi\"}\n)\nprint(response.json())\n'",
        connecting: "Connecting to v4o-code...",
        response: "{\n  'model': 'v4o-code',\n  'latency': '0.55s',\n  'response': 'import RPi.GPIO as GPIO\\nimport time\\n\\nGPIO.setmode(GPIO.BCM)\\nSENSOR_PIN = 17\\n\\ndef read_sensor():\\n    GPIO.setup(SENSOR_PIN, GPIO.IN)\\n    try:\\n        while True:\\n            value = GPIO.input(SENSOR_PIN)\\n            print(f\"Sensor reading: {value}\")\\n            time.sleep(1)\\n    except KeyboardInterrupt:\\n        GPIO.cleanup()\\n\\nif __name__ == \"__main__\":\\n    read_sensor()'\n}"
      }
    }
  ];

  useEffect(() => {
    if (!shouldAnimate) return;

    const phaseTimings = {
      typing: 3000,     // Time for typing
      connecting: 1500, // Time to show connecting
      response: 5000,   // Time to show response
      complete: 500     // Brief pause before next terminal
    };

    const nextPhase = {
      typing: 'connecting',
      connecting: 'response',
      response: 'complete',
      complete: 'typing'
    };

    const timer = setTimeout(() => {
      if (animationPhase === 'complete') {
        setCurrentTerminal((prev) => (prev + 1) % terminals.length);
        setAnimationPhase('typing');
      } else {
        setAnimationPhase(nextPhase[animationPhase]);
      }
    }, phaseTimings[animationPhase]);

    return () => clearTimeout(timer);
  }, [animationPhase, currentTerminal, shouldAnimate]);

  const current = terminals[currentTerminal];
  const content = [];

  // Always show prompt
  content.push(
    <div key="prompt" className={current.textColor}>
      <span>{current.prompt}</span>
    </div>
  );

  // Show command with typing animation
  if (animationPhase === 'typing') {
    content.push(
      <div key="command" className={current.textColor}>
        <span>{current.prompt}</span>
        <TypingAnimation 
          text={current.interaction.command}
          onComplete={() => setShouldAnimate(true)}
        />
      </div>
    );
  } else if (['connecting', 'response', 'complete'].includes(animationPhase)) {
    // Show completed command
    content.push(
      <div key="command-complete" className={current.textColor}>
        <span>{current.prompt}</span>
        <span>{current.interaction.command}</span>
      </div>
    );
  }

  // Show connecting message
  if (['connecting', 'response', 'complete'].includes(animationPhase)) {
    content.push(
      <div key="connecting" className="mt-2 text-blue-400">
        {current.interaction.connecting}
      </div>
    );
  }

  // Show response
  if (['response', 'complete'].includes(animationPhase)) {
    content.push(
      <div key="response" className="mt-2 text-yellow-500 whitespace-pre">
        {current.interaction.response}
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-8">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-light text-white mb-4">
          Runs From Anywhere
        </h2>
        <p className="text-white/50 text-sm">
          Your PC console to a Raspberry Pi - same powerful AI, anywhere you need it
        </p>
      </div>

      <TerminalWindow 
        title={current.title} 
        icon={current.icon}
        bgColor={current.bgColor}
      >
        <div className="space-y-2">
          {content}
        </div>
      </TerminalWindow>

      <div className="text-center mt-8">
        <p className="text-white/70 text-sm">
          v4o-code for programming across platforms
        </p>
      </div>
    </div>
  );
};



const ModelCard = ({ title, description, icon: Icon, metrics }) => (
  <div className="bg-black border border-white/5 rounded p-8 hover:border-white/20 transition-all">
    <div className="flex items-center gap-3 mb-4">
      <Icon className="w-5 h-5 text-white/70" />
      <h3 className="text-lg font-light text-white">{title}</h3>
    </div>
    <p className="text-white/50 text-sm mb-6">{description}</p>
    <div className="grid grid-cols-2 gap-4">
      {metrics.map((metric, index) => (
        <div key={index} className="bg-white/5 p-3 rounded">
          <div className="flex items-center gap-2 mb-1">
            <metric.icon className="w-3 h-3 text-white/30" />
            <span className="text-xs text-white/30">{metric.label}</span>
          </div>
          <div className="text-white/70 text-sm">{metric.value}</div>
        </div>
      ))}
    </div>
  </div>
);

const ModelsShowcase = () => {
  const models = [
    {
      title: "Echidna v4o-think",
      description: "Our flagship reasoning model, optimized for complex analysis and decision making.",
      icon: Brain,
      metrics: [
        { icon: Zap, label: "Speed", value: "15.5ms / token" },
        { icon: BarChart, label: "Accuracy", value: "94.2%" },
        { icon: Database, label: "Context", value: "128K tokens" },
        { icon: Zap, label: "Peak", value: "98.7%" }
      ]
    },
    {
      title: "Echidna v4o-code",
      description: "Specialized in code generation and analysis, with superior performance in computations.",
      icon: Code,
      metrics: [
        { icon: Zap, label: "Accuracy", value: "99.3%" },
        { icon: BarChart, label: "Analysis", value: "96.8%" },
        { icon: Database, label: "Context", value: "64K" },
        { icon: Zap, label: "Score", value: "92.4%" }
      ]
    },
    {
      title: "Echidna-tessera",
      description: "Ultra-compact 800MB model designed for edge deployment with optimized resource usage.",
      icon: Minimize2,
      metrics: [
        { icon: Zap, label: "Size", value: "800 MB" },
        { icon: BarChart, label: "Performance", value: "88.9%" },
        { icon: Clock, label: "Latency", value: "4.2ms" },
        { icon: Zap, label: "Memory", value: "2.1 GB" }
      ]
    }
  ];

  return (
    <section className="py-24 bg-black">
      <div className="max-w-5xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-2xl font-light text-white mb-4">
            Models for Every Need
          </h2>
          <p className="text-white/50 text-sm max-w-xl mx-auto">
            From enterprise-scale reasoning to edge deployment, delivering exceptional performance across all use cases.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {models.map((model, index) => (
            <ModelCard key={index} {...model} />
          ))}
        </div>
      </div>
    </section>
  );
};

const SolutionCard = ({ icon: Icon, title, description, features }) => (
  <div className="bg-black border border-white/5 rounded-lg p-8 hover:border-white/20 transition-all">
    <div className="flex items-center gap-3 mb-4">
      <div className="p-2 bg-white/5 rounded">
        <Icon className="w-5 h-5 text-white/70" />
      </div>
      <h3 className="text-lg font-light text-white">{title}</h3>
    </div>
    <p className="text-white/50 text-sm mb-6 leading-relaxed">{description}</p>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start gap-2">
          <Check className="w-4 h-4 text-white/30 mt-1 flex-shrink-0" />
          <span className="text-sm text-white/50">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const Solutions = () => {
  const solutions = [
    {
      icon: DollarSign,
      title: "Unlimited Use Plans",
      description: "Fixed monthly pricing with unlimited API calls and tokens. No more unpredictable costs.",
      features: [
        "Predictable monthly pricing",
        "No token counting or usage limits",
        "Scale without worrying about costs",
        "Include multiple models in one plan",
        "Enterprise-grade SLA included"
      ]
    },
    {
      icon: Lock,
      title: "Private Deployment",
      description: "Deploy our models directly in your infrastructure for complete control and data privacy.",
      features: [
        "Full data sovereignty",
        "Deploy on your cloud or on-premises",
        "Custom model fine-tuning options",
        "No external API dependencies",
        "End-to-end encryption"
      ]
    },
    {
      icon: Server,
      title: "Edge Optimization",
      description: "Lightweight models optimized for edge deployment without sacrificing performance.",
      features: [
        "800MB model size",
        "4.2ms average latency",
        "Offline capability",
        "Hardware optimized inference",
        "Automatic updates and scaling"
      ]
    }
  ];

  return (
    <section className="py-24 bg-black border-t border-white/5">
      <div className="max-w-5xl mx-auto px-4">
        <div className="text-center mb-16">
          <div className="inline-flex items-center px-3 py-1 rounded-full border border-white/10 text-white/70 text-sm mb-8">
            <Sparkles className="h-3 w-3 mr-2" />
            <span>The Echidna Advantage</span>
          </div>
          
          <h2 className="text-2xl font-light text-white mb-4">
            Enterprise AI Without Compromise
          </h2>
          <p className="text-white/50 text-sm max-w-xl mx-auto">
            Our revolutionary approach eliminates traditional API limitations while providing enterprise-grade security and control.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {solutions.map((solution, index) => (
            <SolutionCard key={index} {...solution} />
          ))}
        </div>

        <div className="mt-16 text-center">
          <a
            href="https://forms.gle/WxZiMyfDYrMxQSQA8"
            target="_blank"
            rel="noopener noreferrer"
            className="group inline-flex items-center px-6 py-3 border border-white/10 rounded text-white hover:bg-white/5 transition-all"
          >
            Get Started
            <ChevronRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </div>
    </section>
  );
};


const ProblemCard = ({ icon: Icon, title, description }) => (
  <div className="bg-black border border-white/5 rounded-lg p-6 hover:border-white/20 transition-all">
    <div className="flex items-center gap-3 mb-3">
      <div className="p-2 bg-white/5 rounded">
        <Icon className="w-5 h-5 text-white/70" />
      </div>
      <h3 className="text-lg font-light text-white">{title}</h3>
    </div>
    <p className="text-white/50 text-sm leading-relaxed">{description}</p>
  </div>
);

const APIIssues = () => {
  const problems = [
    {
      icon: DollarSign,
      title: "Prohibitive Costs",
      description: "Current AI APIs charge up to $0.03 per 1K tokens. For enterprise-scale operations, this can result in millions in API costs alone, making AI integration financially unsustainable."
    },
    {
      icon: Shield,
      title: "Data Privacy Risks",
      description: "Most AI providers store and potentially use your data for model training. This creates significant privacy concerns and regulatory compliance issues for businesses handling sensitive information."
    },
    {
      icon: Server,
      title: "Limited Control",
      description: "Dependency on external APIs means you're bound by their infrastructure, downtime, and policy changes. You have no control over model versions or fine-tuning capabilities."
    },
    {
      icon: Zap,
      title: "Performance Overhead",
      description: "API calls introduce latency and network dependencies. For real-time applications, this overhead can significantly impact user experience and system performance."
    }
  ];

  return (
    <section className="py-24 bg-black border-t border-white/5">
      <div className="max-w-5xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-2xl font-light text-white mb-4">
            The Problem with Current AI APIs
          </h2>
          <p className="text-white/50 text-sm max-w-xl mx-auto">
            Traditional AI APIs come with significant drawbacks that make them impractical for enterprise deployment. We're changing that.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {problems.map((problem, index) => (
            <ProblemCard key={index} {...problem} />
          ))}
        </div>
      </div>
    </section>
  );
};

const SimpleCard = ({ icon: Icon, title, description }) => (
  <div className="bg-black border border-white/5 rounded-lg p-6 hover:border-white/20 transition-all">
    <div className="flex items-center gap-3 mb-3">
      <div className="p-2 bg-white/5 rounded">
        <Icon className="w-5 h-5 text-white/70" />
      </div>
      <h3 className="text-lg font-light text-white">{title}</h3>
    </div>
    <p className="text-white/50 text-sm leading-relaxed">{description}</p>
  </div>
);

const ComparisonCard = ({ title, icon: Icon, theirProblem, ourSolution }) => (
  <div className="bg-black border border-white/5 rounded-lg p-6 hover:border-white/20 transition-all">
    <div className="flex items-center gap-3 mb-4">
      <div className="p-2 bg-white/5 rounded">
        <Icon className="w-5 h-5 text-white/70" />
      </div>
      <h3 className="text-lg font-light text-white">{title}</h3>
    </div>
    
    <div className="space-y-6">
      <div>
        <div className="text-white/30 text-sm mb-2">Other AI Tools:</div>
        <p className="text-white/50 text-sm">{theirProblem}</p>
      </div>
      
      <div>
        <div className="text-white/30 text-sm mb-2">With Echidna AI:</div>
        <p className="text-white/50 text-sm">{ourSolution}</p>
      </div>
    </div>
  </div>
);

const NonTechnicalSection = () => {
  const mainBenefits = [
    {
      icon: Lock,
      title: "Your Own Private AI",
      description: "Unlike ChatGPT or Claude, you get your own private AI instance. It's like having your own personal AI assistant that only works for your company."
    },
    {
      icon: Brain,
      title: "Use As Much As You Want",
      description: "No more counting tokens or messages. Use the AI as much as you need - it's like having an unlimited phone plan, but for AI."
    },
    {
      icon: MessageSquare,
      title: "100% Privacy Guaranteed",
      description: "We store absolutely zero data from your conversations. What happens in your AI stays in your AI - unlike public tools that can use your data."
    }
  ];

  const useCases = [
    {
      icon: Clock,
      title: "Lightning Fast",
      description: "Get instant responses every time. No more waiting in queues or dealing with slow servers like with public AI tools."
    },
    {
      icon: DollarSign,
      title: "Way More Affordable",
      description: "Up to 100x cheaper than other AI services. Stop paying per message and save thousands every month."
    },
    {
      icon: Users,
      title: "Team-Friendly",
      description: "Give everyone on your team their own AI assistant. No technical setup needed - it just works."
    }
  ];

  const comparisons = [
    {
      icon: Lock,
      title: "Privacy & Security",
      theirProblem: "Public AI tools like ChatGPT can see and store all your company's sensitive information.",
      ourSolution: "Your own private AI that nobody else can access. Like having your personal secure office."
    },
    {
      icon: DollarSign,
      title: "Pricing",
      theirProblem: "Other AI services charge for every message. Costs add up quickly as your team uses them.",
      ourSolution: "Unlimited usage plans that cost up to 100x less. Like Netflix - one price, use it as much as you want."
    },
    {
      icon: Clock,
      title: "Speed & Reliability",
      theirProblem: "Public AI tools get slow during busy times and make you wait in line.",
      ourSolution: "Instant responses 24/7 with your dedicated AI. No waiting, no downtime."
    }
  ];

  return (
    <section className="py-24 bg-black border-t border-white/5">
      <div className="max-w-5xl mx-auto px-4">
        <div className="text-center mb-16">
          <div className="inline-flex items-center px-3 py-1 rounded-full border border-white/10 text-white/70 text-sm mb-8">
            <Brain className="h-3 w-3 mr-2" />
            <span>For The Non-Technical</span>
          </div>
          
          <h2 className="text-2xl font-light text-white mb-4">
            Better Than ChatG*T, Cla*de, or Deep*eek
          </h2>
          <p className="text-white/50 text-sm max-w-xl mx-auto">
            All the AI power you need, but private, unlimited, and way more affordable. No technical knowledge required.
          </p>
        </div>

        {/* Main Benefits */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
          {mainBenefits.map((item, index) => (
            <SimpleCard key={index} {...item} />
          ))}
        </div>

        {/* Advantages */}
        <div className="text-center mb-12">
          <h3 className="text-xl font-light text-white mb-4">
            What Makes Us Different
          </h3>
          <p className="text-white/50 text-sm max-w-xl mx-auto mb-8">
            Here's why businesses choose us over regular AI tools:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-20">
            {useCases.map((useCase, index) => (
              <SimpleCard key={index} {...useCase} />
            ))}
          </div>
        </div>

        {/* Direct Comparisons */}
        <div className="text-center mb-12">
          <h3 className="text-xl font-light text-white mb-4">
            Us vs Them - Simple Comparison
          </h3>
          <p className="text-white/50 text-sm max-w-xl mx-auto mb-8">
            We are built on the pain points recieved from users of other LLMs
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {comparisons.map((comparison, index) => (
              <ComparisonCard key={index} {...comparison} />
            ))}
          </div>
        </div>

        {/* Call to Action */}
        
      </div>
    </section>
  );
};


const PricingCalculator = () => {
  const [selectedTask, setSelectedTask] = useState("data-processing");
  const [volume, setVolume] = useState(50);
  const [customTokens, setCustomTokens] = useState({ input: 8000000, output: 4000000 });

  const tasks = {
    "data-processing": { name: "Big Data Processing", inputTokens: 30000000, outputTokens: 12000000 },
    "model-training": { name: "ML Model Training", inputTokens: 25000000, outputTokens: 10000000 },
    "log-analysis": { name: "System Log Analysis", inputTokens: 40000000, outputTokens: 15000000 },
    "custom": { name: "Custom Task", inputTokens: customTokens.input, outputTokens: customTokens.output },
  };

  const models = {
    "gpt-4o": { name: "GPT-4o", inputPrice: 0.03, outputPrice: 0.06 },
    "echidna-v4o": { name: "Echidna V4O Think", flatRate: 200 },
    "o1-mini": { name: "GPT O1", inputPrice: 0.15, cachedInputPrice: 0.0075, outputPrice: 0.60 },
    "deepseek-reasoner": { name: "Deepseek Reasoner", inputPrice: 0.14, outputPrice: 2.19 },
  };

  const calculateCost = (model, task, currentVolume) => {
    if (model.flatRate) return model.flatRate;
    const inputCost = model.cachedInputPrice
      ? (((task.inputTokens * model.inputPrice * 0.5) + (task.inputTokens * model.cachedInputPrice * 0.5)) * currentVolume) / 1e6
      : (task.inputTokens * model.inputPrice * currentVolume) / 1e6;
    const outputCost = (task.outputTokens * model.outputPrice * currentVolume) / 1e6;
    return inputCost + outputCost;
  };

  return (
    <section className="py-6 px-4 bg-black border-t border-white/10 text-white">
      <div className="max-w-4xl mx-auto">
        {/* Header & Task Selection */}
        <div className="flex flex-col sm:flex-row items-center justify-between mb-4 gap-3">
          <h2 className="flex items-center gap-2 text-base sm:text-lg font-semibold">
            <Calculator className="h-4 w-4 text-white/70" /> Pricing Calculator
          </h2>
          <select
            value={selectedTask}
            onChange={(e) => setSelectedTask(e.target.value)}
            className="bg-black border border-white/20 px-3 py-2 rounded-md text-sm w-full sm:w-auto"
          >
            {Object.entries(tasks).map(([key, task]) => (
              <option key={key} value={key}>{task.name}</option>
            ))}
          </select>
        </div>

        {/* Custom Task Inputs */}
        {selectedTask === "custom" && (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-4">
            <input
              type="number"
              min="1000000"
              step="1000000"
              value={customTokens.input}
              onChange={(e) => setCustomTokens({ ...customTokens, input: Number(e.target.value) })}
              className="bg-black border border-white/20 px-3 py-2 rounded-md text-sm w-full"
              placeholder="Input Tokens"
            />
            <input
              type="number"
              min="500000"
              step="500000"
              value={customTokens.output}
              onChange={(e) => setCustomTokens({ ...customTokens, output: Number(e.target.value) })}
              className="bg-black border border-white/20 px-3 py-2 rounded-md text-sm w-full"
              placeholder="Output Tokens"
            />
          </div>
        )}

        {/* Volume Selector */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4 gap-3">
          <label className="text-sm">Users: {volume.toLocaleString()}</label>
          <input
            type="range"
            min="1"
            max="1000"
            step="1"
            value={volume}
            onChange={(e) => setVolume(Number(e.target.value))}
            className="w-full sm:w-1/2"
          />
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {Object.entries(models).map(([key, model]) => (
            <div key={key} className="p-3 border border-white/20 rounded-md text-center bg-white/5 hover:bg-white/10">
              <h3 className="text-sm font-medium">{model.name}</h3>
              <p className="text-xs text-white/50">{model.flatRate ? "Flat Rate" : "Per Usage"}</p>
              <div className="text-lg font-semibold mt-2">
                ${calculateCost(model, tasks[selectedTask], volume).toFixed(2)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};




const Cta = () => {
  return (
    <div className="mt-16 pb-16 text-center"> {/* Added pb-16 here */}
      <div className="max-w-2xl mx-auto p-6 bg-white/5 rounded-lg">
        <h3 className="text-lg font-light text-white mb-3">
          Want to See How It Works?
        </h3>
        <p className="text-white/50 text-sm mb-6">
          Get a friendly demo in plain English - no tech talk, we promise!
        </p>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <a
            href="https://forms.gle/WxZiMyfDYrMxQSQA8"
            target="_blank"
            rel="noopener noreferrer"
            className="group w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-white/10 rounded text-white hover:bg-white/5 transition-all"
          >
            Schedule a Demo
            <ChevronRight className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" />
          </a>
          <span className="text-white/30 text-sm">See the difference yourself</span>
        </div>
      </div>
    </div>
  );
};




const LandingPage = () => {
  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <Hero />
      <NonTechnicalSection />
      <APIIssues />
      <Solutions />
      <ModelsShowcase />
      <PricingCalculator />
      <TerminalAnimation />
      <Cta />
    </div>
  );
};
                                                                                                                                                                                                                                                                                                                                 

export default LandingPage;